import React from 'react';

function BlogPost() {
    return (
        <div className="blog-post">
            <div className="blog-header">
                <h1>こんにちは</h1>
                <span>Posted: March 15, 2020</span>
            </div>
            <div className="blog-content">
                <p>
                    I've never been good at creating posts, but as an aspiring developer 
                    I figured I should have my own website. This is a first draft, if you will, of what 
                    I hope the website will be one day. I've always wanted to get into programming and game 
                    development and I'm finally making it happen (very slowly).
                </p>
                <p>
                    To that end I have semi-recently received a bachelor's degree in computer science and I am
                    beginning development on a game with one of my friends. The game is going to be in the dungeon
                    crawler genre and developed for PC. I plan on using this site for sharing updates to this game
                    and others in the future. 
                </p>
                <p>I suppose that is all for now.</p>
            </div>
            <div className="blog-footer">
                <br />
                <span>SEE YOU SPACE COWBOY...</span>
            </div>
        </div>
    )
}

export default BlogPost
import React from 'react';
import BlogPost from './blogpost/blogpost';

function Home() {
    return (
        <main className="home">
            <BlogPost />
        </main>
    )
}

export default Home;
import React from 'react';

function Header() {
    return (
        <header className="app-header">
            <div className="header-logo">
                <p>Enshaedn</p>
            </div>
        </header>
    )
}

export default Header;